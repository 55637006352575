<template>
    <div class="create-status-wrapper">
        <v-text-field
                class="evi-text-field"
                color="#44D370"
                v-model="status.name"
                :disabled="!hasRight('dealStatuses.manage')"
        >
            <template v-slot:label>
                Название статуса
                <span class="important">*</span>
            </template>
        </v-text-field>
        <div class="status__color mt-5">
            <span>Цвет статуса</span>
            <div class="status__picker mt-2">
                <v-color-picker
                    dot-size="25"
                    hide-canvas
                    show-swatches
                    hide-inputs
                    swatches-max-height="200"
                    class="mt-5"
                    v-model="status.color"
                    :disabled="!hasRight('dealStatuses.manage')"
                />
            </div>
            <div class="status__buttons d-flex align-center" v-if="hasRight('dealStatuses.manage')">
                <v-btn class="evi-button-green mr-7" @click="$emit('reset')">
                    Сброс
                </v-btn>
                <v-btn class="evi-button-green evi-button-green--fill" :disabled="!status.name" @click="save">
                    Сохранить
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import {hasRight} from '@/utils/access/hasRight';


    export default {
        name: "StatusForm",
        props: {
            selectedStatus: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            status: {},
        }),
        methods: {
            hasRight: hasRight,
            reset () {
                this.status = this.selectedStatus;
            },
            save () {
                this.$emit('saveStatus', this.status);
            }
        },
        watch: {
            selectedStatus(val) {
                this.status = val;
            }
        },
        mounted() {
            this.status = this.selectedStatus;
        }
    }
</script>

<style lang="scss" scoped>
    .create-status-wrapper {
        max-width: 350px;
    }
    .status {
        &__color {
            span {
                font-weight: normal;
                font-size: 14px;
                color: #464A53;
            }
        }
        &__picker {
            background-color: #FAFAFA;
            width: 100%;
            height: 320px;
            display: flex;
            justify-content: center;
            margin-top: 17px !important;
        }
        &__buttons {
            margin-top: 50px;
        }
    }
</style>
